var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"example-headImg",attrs:{"src":require("../assets/image/example/02-1banner.png")}}),_vm._m(0),_c('div',{staticClass:"head-list"},[_c('div',{staticClass:"head-list-item head-list-item-ischeck",staticStyle:{"margin-left":"26.3rem"},on:{"click":function($event){return _vm.goAssignBlock('block'+ 0,50)}}},[_c('span',{staticClass:"title"},[_vm._v("业务痛点")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 1,50)}}},[_c('span',{staticClass:"title"},[_vm._v("解决方案")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 2,50)}}},[_c('span',{staticClass:"title"},[_vm._v("方案收益")])])]),_c('div',{ref:"block0",staticClass:"container-1",staticStyle:{"display":"flex"}},[_vm._m(1),_vm._m(2)]),_c('div',{ref:"block1",staticClass:"container-2",staticStyle:{"display":"flex","height":"67rem"}},[_vm._m(3),_vm._m(4)]),_c('div',{ref:"block2",staticClass:"container-1",staticStyle:{"display":"flex","margin-bottom":"10rem"}},[_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-explain"},[_c('div',{staticClass:"head-explain-title"},[_vm._v("采购协同")]),_c('div',{staticClass:"head-explain-content"},[_vm._v("管理透明，高效执行")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("业务痛点")]),_c('div',{staticClass:"purchase-content"},[_vm._v("形式多样不规范、重复动作耗工时")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"purchase-title"},[_vm._v("发运不精准")]),_c('div',{staticClass:"purchase-content"},[_vm._v("生产计划、安全库存、供应商供货能力信息不透明，采购仅凭经验拆分采购订单，无法真正实现按需送货；")]),_c('div',{staticClass:"purchase-title"},[_vm._v("流程不透明")]),_c('div',{staticClass:"purchase-content"},[_vm._v("各环节业务流转需要采购参与和推动，“有问题、找采购”式工作模式，采购负担繁重;"),_c('br'),_vm._v(" 无信息共享平台，线下多媒介的沟通方式，供应商、采购、仓储、财务协同难;")]),_c('div',{staticClass:"purchase-title"},[_vm._v("绩效管控难")]),_c('div',{staticClass:"purchase-content"},[_vm._v("供应商考核体系不完善，供应质量问题追溯难; "),_c('br'),_vm._v(" 缺少供应商全采购周期的持续改进管理;")]),_c('div',{staticClass:"purchase-title"},[_vm._v("对账效率低")]),_c('div',{staticClass:"purchase-content"},[_vm._v("手工对账，整体时效性低、易出错;"),_c('br'),_vm._v(" 流程繁琐不透明，采购需全程跟进。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("解决方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('img',{staticClass:"solution-img",attrs:{"src":require("../assets/image/example/02-1解决方案.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("方案收益")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('img',{staticClass:"benefits-img",attrs:{"src":require("../assets/image/example/02-1方案收益.svg")}})])
}]

export { render, staticRenderFns }