<template>
    <div>
        <img class="example-headImg" src="../assets/image/example/02-1banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">采购协同</div>
            <div class="head-explain-content">管理透明，高效执行</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck " style="margin-left:26.3rem" @click="goAssignBlock('block'+ 0,50)"><span class="title">业务痛点</span></div>
            <div class="head-list-item" @click="goAssignBlock('block'+ 1,50)"><span class="title" >解决方案</span></div>
            <div class="head-list-item" @click="goAssignBlock('block'+ 2,50)"><span class="title">方案收益</span></div>
        </div>
        <div class="container-1" style="display:flex" ref="block0">
            <div class="purchase-content-left">
                <div class="purchase-title">业务痛点</div>
                <div class="purchase-content">形式多样不规范、重复动作耗工时</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-title">发运不精准</div>
                <div class="purchase-content">生产计划、安全库存、供应商供货能力信息不透明，采购仅凭经验拆分采购订单，无法真正实现按需送货；</div>
                <div class="purchase-title">流程不透明</div>
                <div class="purchase-content">各环节业务流转需要采购参与和推动，“有问题、找采购”式工作模式，采购负担繁重;<br/>
                无信息共享平台，线下多媒介的沟通方式，供应商、采购、仓储、财务协同难;</div>
                <div class="purchase-title">绩效管控难</div>
                <div class="purchase-content">供应商考核体系不完善，供应质量问题追溯难; <br/>
                缺少供应商全采购周期的持续改进管理;</div>
                <div class="purchase-title">对账效率低</div>
                <div class="purchase-content">手工对账，整体时效性低、易出错;<br/>
                    流程繁琐不透明，采购需全程跟进。</div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 67rem;" ref="block1">
            <div class="purchase-content-left">
                <div class="purchase-title">解决方案</div>
            </div>
            <div class="purchase-content-right">
                <img class="solution-img" src="../assets/image/example/02-1解决方案.svg"/>
            </div>
        </div>
        <div class="container-1" style="display:flex;margin-bottom:10rem" ref="block2">
            <div class="purchase-content-left">
                <div class="purchase-title">方案收益</div>
            </div>
            <div class="purchase-content-right">
                <img class="benefits-img" src="../assets/image/example/02-1方案收益.svg"/>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',
    metaInfo:{
        meta:[
        { name:'keywords', content:'采购协同,发运不精准,流程不透明,绩效管控难,对账效率低' },
        { name:'description', content:'管理透明，高效执行。' }
        ]
    },
    data() {
        return {
            navgatorIndex: 0,
            navOffsetTop: 0,
        };
    },

    mounted() {
        this.activeIndex = '2'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
        // 监听滚动事件
        window.addEventListener('scroll', this.fiexdNav)
        this.getData()
    },
    destroyed () {
        // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
        window.removeEventListener('scroll', this.fiexdNav)
    },
    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
        goAssignBlock(el, speed) {
        const navHeight = document.querySelector('.head-list').offsetHeight //导航栏的高度
        let _this = this;
        let windowH = window.innerHeight; //浏览器窗口高度
        let h = this.$refs[el].offsetHeight; //模块内容高度
        let t = this.$refs[el].offsetTop - navHeight - speed; //模块相对于内容顶部的距离
        let top = t ; //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
        let scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop; //滚动条距离顶部高度
        let currentTop = scrollTop; //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
        let requestId;
        //采用requestAnimationFrame，平滑动画
        function step() {
            //判断让滚动条向上滚还是向下滚
            if (scrollTop < top) {
                if (currentTop <= top) {
                    //   window.scrollTo(x,y) y为上下滚动位置
                    window.scrollTo(0, currentTop);
                    requestId = window.requestAnimationFrame(step);
                } else {
                    window.cancelAnimationFrame(requestId);
                }
                //向下滚动
                currentTop += speed;
            } else {
                if (top <= currentTop) {
                    //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
                    window.scrollTo(0, currentTop);
                    requestId = window.requestAnimationFrame(step);
                } else {
                    window.cancelAnimationFrame(requestId);
                }
                //向上滚动
                currentTop -= speed;
            }
        }
        window.requestAnimationFrame(step);
        },
        /** 设置导航条nav到达页面顶部时固定 **/
        // 1.获取导航条nav的offsetTop值，存储在data中（注：之所以不放在滚动事件中，是为了以防添加固定样式后offsetTop值为零,导致页面需要滚动到最上面才可以回到原位）
        getData () {
            this.navOffsetTop = document.querySelector('.head-list').offsetTop + 60
            console.log('this.navOffsetTop',this.navOffsetTop)
        },
        fiexdNav () {
            const navHeight = document.querySelector('.head-list').offsetHeight //导航栏的高度
            // 2.获取当前页面的卷曲高度
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            const nav = document.querySelector('.head-list')
            // 3.判断卷曲高度是否大于等于导航条的offsetTop值
            if (scrollTop > this.navOffsetTop) {
                //   3.1若满足，则给nav导航添加固定样式
                nav.classList.add('fixedNav')
            } else {
                //   3.2若不满足，则删除nav导航的固定样式
                nav.classList.remove('fixedNav')
            }

            /** 当滚动到一定区域时给导航项添加选中样式 **/
            //  1.获取所有锚点元素
            // 2.获取锚点元素的offsetTop值，并收集在一个数组
            const contentsOffsetTop = []
            // contents.forEach(item => {
            //     contentsOffsetTop.push(item.offsetTop)
            //     console.log('2222',item.offsetTop)
            // })
            contentsOffsetTop.push(this.$refs['block0'].offsetTop)
            contentsOffsetTop.push(this.$refs['block1'].offsetTop)
            contentsOffsetTop.push(this.$refs['block2'].offsetTop)
            // console.log('contentsOffsetTop',contentsOffsetTop)
            // console.log('contentsOffsetTop',contentsOffsetTop)
            // 3.获取页面高度
            const pageHeight = window.innerHeight
            // 4.获取nav的子元素
            const navChildren = document.querySelectorAll('.head-list .head-list-item')
            for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[0].classList.add('head-list-item-ischeck')
                }
            if(scrollTop>contentsOffsetTop[0] + this.$refs['block0'].offsetHeight/2){
                for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[1].classList.add('head-list-item-ischeck')
                }
            }
            if(scrollTop>contentsOffsetTop[1] + this.$refs['block1'].offsetHeight/2){
                for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[2].classList.add('head-list-item-ischeck')
                }
            }
        },
        /**
         *设置点击导航跳转到指定选择器对应的锚点元素
        * @param {*} selector
        **/
        skipTo (selector) {
            const navHeight = document.querySelector('.head-list-item').offsetHeight
            // scrollIntoView() js原生方法，实现锚点滚动过渡
            const target = document.querySelector(selector)
            target.scrollIntoView({ behavior: 'smooth' })
            // scrollTo() 把内容滚动到指定的坐标。减去导航高度的目的：导航用定位固定在顶部，如果不减去，导航栏会遮挡一部分内容
            window.scrollTo(0, target.offsetTop - navHeight)
        }
    },
};
</script>

<style lang="less" scoped>
.purchase-content-left{
    width:33rem;
    text-align: justify;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right{
    text-align: justify;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 106.5rem;
    height: 56.3rem;
}
.benefits-img{
    width: 97.7rem;
    height: 79.3rem;
}
</style>
